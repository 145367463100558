const API_PATH = 'https://api.prestavale.mx';

const config = {
  api: {
    url: `${API_PATH}/api`,
  },
  auth: {
    url: 'http://localhost:3010/ms',
    apiKey: 'pv123',
  },
  gateway: {
    url: 'http://localhost:4000',
    publicKey: 'pv123',
  },
  incode: {
    url: 'https://demo-api.incodesmile.com',
    apiKey: '561f1ed8ac0485036c0ccf99519b3edea491f12a',
    onboardingUrl: 'https://demo-onboarding.incodesmile.com',
    flowId: '64262c8a39deb419ee16efd6',
    tenant: '17mf804',
  },
  self: {
    url: 'http://localhost:3000',
  },
};

export default config;
