/* eslint-disable indent */
import React, { useState, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { get, isEmpty, head } from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import parse from 'html-react-parser';
import 'moment/locale/es';

import Loader from '../../components/Loader';

import config from '../../config';
import arrowUp from '../../assets/arrow-up.svg';
import arrowDown from '../../assets/arrow-down.svg';
import icons from '../../assets/icons.svg';
import threeDots from '../../assets/three-dots.svg';

import {
  Content,
  NavBarContainer,
  ContainerGeneral,
  ContentButonGroup,
  ContentButon,
  ContainerData,
  ContainerDialogTerms,
} from './styledComponents';

import incode from '../../incode';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const options = {
  // eslint-disable-next-line consistent-return
  replace: domNode => {
    if (domNode.attribs && domNode.attribs.class === 'remove') {
      return <></>;
    }
  },
};

const TextMaskCustom = props => {
  const { inputRef, placeholder, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[4-5]/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      placeholder={placeholder}
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
};

// This only works for Android, you need to handle iOS
const ResetPermissions = async ({ onTryAgain }) => (
  <div className="reset-permissions">
    <h1>Follow the next steps:</h1>
    <ul>
      <li>
        <span className="number">1</span> <p>Tap the 3 dots</p>{' '}
        <img className="three-dots" alt="three dots" src={threeDots} />
        <img
          className="arrow-up"
          src={arrowUp}
          alt="arrow pointing to the three dots"
        />
      </li>
      <li>
        <span className="number">2</span> <p>Tap this icon</p>{' '}
        <img
          src={arrowDown}
          className="arrow-down"
          alt="arrow pointing to icon with i"
        />
        <div>
          <img src={icons} alt="bar icons" />
        </div>
      </li>
      <li>
        <span className="number">3</span>{' '}
        <p>
          Tap in <span className="blue">Site settings</span>
        </p>
      </li>
      <li>
        <span className="number">4</span>{' '}
        <span className="blue">Allow Permission</span>{' '}
        <p style={{ marginLeft: 10 }}>to Camera</p>
      </li>
    </ul>
    <div className="button-container">
      <button type="button" onClick={onTryAgain}>
        Try Again
      </button>
    </div>
  </div>
);

ResetPermissions.propTypes = {
  onTryAgain: PropTypes.func,
};

const RegistroIncode = props => {
  const containerRef = useRef();

  const [termsAgreed, setTermsAgreed] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [termsAgreedOpen, setTermsAgreedOpen] = useState(false);

  const [notFound, setNotFound] = useState(null);
  const [loading, setLoading] = useState(true);

  const [errorText, setErrorText] = useState('');

  const token = get(props, 'match.params.token', '');

  const [clientInfo, setClientInfo] = useState(null);
  const [loadingFull, setLoadingFull] = useState(false);

  const [successText, setSuccessText] = useState('');
  const [startExchange, setStartExchange] = useState(false);

  const [session, setSession] = useState();
  const [resetPermissions, setResetPermissions] = useState(false);

  const [faceMatch, setFaceMatch] = useState(false);
  const [liveness, setLiveness] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const [urlPerfil, setUrlPerfil] = useState(null);
  const [registroCompleto, setRegistroCompleto] = useState('');
  const [afiliado, setAfiliado] = useState({});

  const start = () => {
    setStartExchange(true);
  };

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const handleRegister = async () => {
    try {
      setLoadingFull(true);

      const formData = new FormData();

      formData.set('token', token);
      formData.set('interviewId', session.interviewId);
      formData.set('id', urlPerfil);
      formData.set('origen', 'incode');

      const response = await Axios.patch(
        `${config.api.url}/preregistroClientes/finalizar`,
        formData,
        {
          'Content-Type': 'multipart/form-data',
        },
      );

      const isSuccess = get(response, 'data.success', false);

      if (!isSuccess) {
        setErrorText(response.data.message);
        setFaceMatch(false);
        if (response.data.codeName === 'CURP_ERROR') {
          incode.renderCamera('front', containerRef.current, {
            onSuccess: frontIneSuccess,
            onError: handleErrorFront,
            token: session,
            noWait: true,
            numberOfTries: -1,
            showTutorial: true,
            showCustomCameraPermissionScreen: true,
            showDoublePermissionsRequest: true,
          });
        } else {
          incode.renderCamera('selfie', containerRef.current, {
            onSuccess: selfieSuccess,
            onError: handleErrorSelfie,
            token: session,
            numberOfTries: -1,
            showTutorial: true,
            showCustomCameraPermissionScreen: true,
            showDoublePermissionsRequest: true,
          });
        }

        return;
      }

      setSuccessText('Cliente registrado con éxito');
      setRegistroCompleto(
        'El registro se ha realizado con éxito. Puede cerrar el navegador.',
      );
    } catch (error) {
      console.log('error');
      console.log(JSON.parse(JSON.stringify(error)));
      setErrorText(error.message);

      incode.renderFaceMatch(containerRef.current, {
        onSuccess: faceMatchSuccess,
        onError: handleErrorMatch,
        token: session,
        liveness,
        userExists,
      });
    } finally {
      setLoadingFull(false);
    }
  };

  const handleErrorFront = e => {
    if (e.type === 'permissionDenied') {
      setResetPermissions(true);
      return;
    }

    incode.renderCamera('front', containerRef.current, {
      onSuccess: frontIneSuccess,
      onError: handleErrorFront,
      token: session,
      noWait: true,
      numberOfTries: -1,
      showTutorial: true,
      showCustomCameraPermissionScreen: true,
      showDoublePermissionsRequest: true,
    });
  };

  const handleErrorBack = e => {
    if (e.type === 'permissionDenied') {
      setResetPermissions(true);
      return;
    }

    incode.renderCamera('back', containerRef.current, {
      onSuccess: backIneSuccess,
      onError: handleErrorBack,
      token: session,
      numberOfTries: -1,
      showTutorial: true,
      showCustomCameraPermissionScreen: true,
      showDoublePermissionsRequest: true,
    });
  };

  const handleErrorSelfie = e => {
    if (e.type === 'permissionDenied') {
      setResetPermissions(true);
      return;
    }

    incode.renderCamera('selfie', containerRef.current, {
      onSuccess: selfieSuccess,
      onError: handleErrorSelfie,
      token: session,
      numberOfTries: -1,
      showTutorial: true,
      showCustomCameraPermissionScreen: true,
      showDoublePermissionsRequest: true,
    });
  };

  const handleErrorMatch = () => {
    incode.renderFaceMatch(containerRef.current, {
      onSuccess: faceMatchSuccess,
      onError: handleErrorMatch,
      token: session,
      liveness,
      userExists,
    });
  };

  const faceMatchSuccess = async () => {
    if (faceMatch) {
      await handleRegister();
    } else {
      incode.renderCamera('selfie', containerRef.current, {
        onSuccess: selfieSuccess,
        onError: handleErrorSelfie,
        token: session,
        numberOfTries: -1,
        showTutorial: true,
        showCustomCameraPermissionScreen: true,
        showDoublePermissionsRequest: true,
      });
    }
  };

  useEffect(() => {
    if (session) {
      incode.renderFaceMatch(containerRef.current, {
        onSuccess: faceMatchSuccess,
        onError: handleErrorMatch,
        token: session,
        liveness,
        userExists,
      });
    }
  }, [faceMatch]);

  const selfieSuccess = async data => {
    if (data.faceMatch) {
      // Subir imagen a BD
      try {
        const b64Blob = await b64toBlob(data.imageBase64);
        const selfie = new File([b64Blob], 'selfie.png', {
          type: 'image/png',
        });
        setUrlPerfil(selfie);

        setFaceMatch(data.faceMatch);
        setLiveness(data.liveness);
        setUserExists(data.existingUser);
      } catch (e) {
        setErrorText('No se puedo subir foto de perfil. Intente nuevamente.');

        incode.renderCamera('selfie', containerRef.current, {
          onSuccess: selfieSuccess,
          onError: handleErrorSelfie,
          token: session,
          numberOfTries: -1,
          showTutorial: true,
          showCustomCameraPermissionScreen: true,
          showDoublePermissionsRequest: true,
        });
      }
    }
  };

  const backIneSuccess = async data => {
    // Subir imagen de selfie a BD
    try {
      setLoadingFull(true);
      await incode.processId({ token: session.token });

      const formData = new FormData();
      const b64Blob = await b64toBlob(data.frontIdImage);
      const ineFront = new File([b64Blob], 'reversoIne.png', {
        type: 'image/png',
      });

      formData.append('files', ineFront);
      formData.append('token', token);
      formData.append('tipo', 'Atras');
      await Axios.patch(
        `${config.api.url}/preregistroClientes/subirIdentificacion`,
        formData,
      );

      const ocrData = await incode.ocrData({ token: session.token });
      if (isEmpty(ocrData.curp)) {
        setErrorText('No se puedo verificar su INE. Intente nuevamente.');

        incode.renderCamera('front', containerRef.current, {
          onSuccess: frontIneSuccess,
          onError: handleErrorFront,
          token: session,
          noWait: true,
          numberOfTries: -1,
          showTutorial: true,
          showCustomCameraPermissionScreen: true,
          showDoublePermissionsRequest: true,
        });
        return;
      }

      setLoadingFull(false);

      incode.renderCamera('selfie', containerRef.current, {
        onSuccess: selfieSuccess,
        onError: handleErrorSelfie,
        token: session,
        numberOfTries: -1,
        showTutorial: true,
        showCustomCameraPermissionScreen: true,
        showDoublePermissionsRequest: true,
      });
    } catch (e) {
      setErrorText('No se puedo subir reverso de su INE. Intente nuevamente.');

      incode.renderCamera('back', containerRef.current, {
        onSuccess: backIneSuccess,
        onError: handleErrorBack,
        token: session,
        numberOfTries: -1,
        showTutorial: true,
        showCustomCameraPermissionScreen: true,
        showDoublePermissionsRequest: true,
      });
    }
  };

  const frontIneSuccess = async data => {
    try {
      const formData = new FormData();
      const b64Blob = await b64toBlob(data.frontIdImage);
      const ineFront = new File([b64Blob], 'frenteIne.png', {
        type: 'image/png',
      });

      formData.append('files', ineFront);
      formData.append('token', token);
      formData.append('tipo', 'Frente');
      await Axios.patch(
        `${config.api.url}/preregistroClientes/subirIdentificacion`,
        formData,
      );

      incode.renderCamera('back', containerRef.current, {
        onSuccess: backIneSuccess,
        onError: handleErrorBack,
        token: session,
        numberOfTries: -1,
        showTutorial: true,
        showCustomCameraPermissionScreen: true,
        showDoublePermissionsRequest: true,
      });
    } catch (e) {
      console.log('ERROR SUBIR INE FRENTE');
      console.log(e);
      setErrorText('No se puedo subir frente de su INE. Intente nuevamente.');

      incode.renderCamera('front', containerRef.current, {
        onSuccess: frontIneSuccess,
        onError: handleErrorFront,
        token: session,
        noWait: true,
        numberOfTries: -1,
        showTutorial: true,
        showCustomCameraPermissionScreen: true,
        showDoublePermissionsRequest: true,
      });
    }
  };

  useEffect(() => {
    if (startExchange && session) {
      incode.renderCamera('front', containerRef.current, {
        onSuccess: frontIneSuccess,
        onError: handleErrorFront,
        token: session,
        noWait: true,
        numberOfTries: -1,
        showTutorial: true,
        showCustomCameraPermissionScreen: true,
        showDoublePermissionsRequest: true,
        assistedOnboarding: true,
      });
    }
  }, [startExchange]);

  useEffect(() => {
    fetchData();
    fetchConfig();
  }, []);

  useEffect(() => {
    (async () => {
      await initIncode();
    })();

    async function initIncode() {
      if (notFound === false) {
        await incode.warmup();
        const thisSession = await incode.createSession('ALL', null, {
          configurationId: '66e1eb5c5e9c4630aa54682a',
        });
        setSession(thisSession);
      }
    }
  }, [notFound]);

  const handleTermsAgreedOpen = () => {
    setTermsAgreedOpen(true);
  };

  const handleTermsAgreedClose = () => {
    setTermsAgreedOpen(false);
  };

  const fetchConfig = async () => {
    try {
      const response = await Axios.get(`${config.api.url}/configuraciones`);
      setTermsAndConditions(get(response, 'data[0].terminosCondiciones', ''));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await Axios.get(
        `${config.api.url}/preregistroClientes`,
        {
          params: {
            filter: {
              where: {
                token,
                estatus: 'activo',
              },
              include: {
                relation: 'afiliado',
              },
            },
          },
        },
      );
      if (isEmpty(response.data)) {
        setNotFound(true);
      } else {
        setNotFound(false);
        setClientInfo(head(response.data));
        if (head(response.data).afiliado) {
          setAfiliado(head(response.data).afiliado);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoadingFull(false);
    }
  };

  if (resetPermissions) {
    return <ResetPermissions onTryAgain={() => setResetPermissions(false)} />;
  }

  return (
    <ContainerGeneral>
      <NavBarContainer color={!isEmpty(afiliado) ? afiliado.color : ''}>
        <Link to="/">
          <img
            className="logo"
            src={!isEmpty(afiliado) ? afiliado.logo : '/images/logo-new.png'}
            alt="Logo PrestaVale blanco"
          />
        </Link>
      </NavBarContainer>
      {startExchange === false && (
        <>
          {loading && <Loader />}
          {notFound && (
            <Content>
              <div>Este link ha expirado.</div>
            </Content>
          )}
          {!loading && !notFound && (
            <>
              <Content>
                <ContainerData>
                  <h3>Iniciarás tu registro de cliente</h3>
                </ContainerData>
                <ContainerData>
                  <h3>CURP: </h3>
                  <div className="numbertwo">{`${clientInfo.curp}`}</div>
                </ContainerData>
                <ContainerData>
                  <h3>Celular: </h3>
                  <div className="numbertwo">{clientInfo.celular}</div>
                </ContainerData>

                <ContainerData>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="accept"
                        color={!isEmpty(afiliado) ? 'secondary' : 'primary'}
                      />
                    }
                    className="accept-check"
                    onChange={event => setTermsAgreed(event.target.checked)}
                  />
                  <Button onClick={handleTermsAgreedOpen}>
                    Aceptar términos y condiciones.
                  </Button>
                </ContainerData>

                <ContentButonGroup>
                  <ContentButon>
                    <Button
                      fullWidth
                      variant="contained"
                      color={!isEmpty(afiliado) ? 'secondary' : 'primary'}
                      size="large"
                      onClick={start}
                      disabled={!termsAgreed}
                    >
                      Iniciar registro
                    </Button>
                  </ContentButon>
                </ContentButonGroup>
              </Content>
            </>
          )}
        </>
      )}

      {Boolean(registroCompleto) && (
        <Content>
          <div className="greeting">{registroCompleto}</div>
        </Content>
      )}

      {startExchange && isEmpty(registroCompleto) && (
        <Content>
          <div ref={containerRef}></div>
        </Content>
      )}

      {loadingFull && <Loader />}
      <Dialog
        open={termsAgreedOpen}
        onClose={handleTermsAgreedClose}
        maxWidth="sm"
      >
        <IconButton
          style={{ position: 'absolute', top: 16, right: 16, zIndex: 1 }}
          onClick={handleTermsAgreedClose}
        >
          <CloseIcon />
        </IconButton>
        <ContainerDialogTerms>
          <div>{parse(termsAndConditions, options)}</div>
        </ContainerDialogTerms>
      </Dialog>
      <Snackbar
        open={Boolean(successText)}
        autoHideDuration={6000}
        onClose={() => setSuccessText('')}
      >
        <Alert onClose={() => setSuccessText('')} severity="success">
          {successText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(errorText)}
        autoHideDuration={6000}
        onClose={() => setErrorText('')}
      >
        <Alert onClose={() => setErrorText('')} severity="error">
          {errorText}
        </Alert>
      </Snackbar>
    </ContainerGeneral>
  );
};

export default RegistroIncode;
