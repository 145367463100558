import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  max-width: 400px;
  margin: 0 auto;

  .button {
    background-color: #00ae42;
    padding: 15px;
    border-radius: 24px;
    color: #fff;
    font-weight: bold;
  }

  .button:hover {
    background: #0dd260;
  }
`;

export const Box = styled.div`
  align-self: flex-start;
`;

export const Title = styled.h1`
  font-size: 28px;
  text-align: left;
`;

export const Description = styled.p`
  margin: 0;
  font-size: 18px;
`;
