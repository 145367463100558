import configDev from './config.development';

import configProd from './config.production';

let config; //eslint-disable-line
switch (process.env.REACT_APP_ENVIRONMENT) {
  case 'development':
    config = configDev;
    break;
  case 'test':
    config = configDev;
    break;
  case 'staging':
    config = configDev;
    break;
  case 'production':
    config = configProd;
    break;
  default:
    config = configProd;
    break;
}

export default config; // eslint-disable-line
