/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable func-names */
import React, { useState, useRef, useEffect } from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Camera from 'react-html5-camera-photo';
import VideoRecorder from 'react-video-recorder';
import { isCreditCard } from 'validator';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { get, isEmpty, filter, toLower, includes } from 'lodash';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Numeral from 'numeral';
import parse from 'html-react-parser';
import SignatureCanvas from 'react-signature-canvas';
import 'moment/locale/es';

import { Card, CardContent, RadioGroup } from '@material-ui/core';
import moment from 'moment';
import VideoActions from './videoActions';

import Loader from '../../components/Loader';

import config from '../../config';

import {
  Content,
  ContainerCard,
  NavBarContainer,
  VideoSection,
  InputFileContainer,
  Input,
  LoadingLabel,
  ContainerGeneral,
  ContentButonGroup,
  ContentButon,
  ContentDialog,
  ContainerData,
  ButtonGrupDialog,
  ContentTerms,
  ContainerDialogTerms,
  ContainerCanvas,
} from './styledComponents';
import BeneficiariosForm from '../../components/BeneficiariosForm';

const numberRegex = /^\d+$/;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const options = {
  // eslint-disable-next-line consistent-return
  replace: domNode => {
    if (domNode.attribs && domNode.attribs.class === 'remove') {
      return <></>;
    }
  },
};

const TextMaskCustom = props => {
  const { inputRef, placeholder, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[4-5]/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      placeholder={placeholder}
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
};

function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function(res) {
      return res.arrayBuffer();
    })
    .then(function(buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

const CanjeValeDigital = props => {
  const [activeStep, setActiveStep] = React.useState(0);

  const [cancelVaucher, setCancelVaucher] = useState(false);

  const [termsAgreed, setTermsAgreed] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [identityValidationAttempts, setIdentityValidationAttempts] = useState(
    0,
  );
  const [validationAttempt, setValidationAttempt] = useState('');
  const [errorSelfie, setErrorSelfie] = useState('');
  const [cameraOpen, setCameraOpen] = useState(false);
  const [termsAgreedOpen, setTermsAgreedOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(null);
  const [cameraStarted, setCameraStarted] = useState(false);

  const [videoIdentity, setVideo] = useState(null);
  const [videoOpen, setVideoOpen] = useState(false);
  const [videoURL, setVideoURL] = useState(null);
  const [editCard, setEditCard] = useState(false);

  const [cardTouched, setCardTouched] = useState(false);
  const [card, setCard] = useState('');

  const [notFound, setNotFound] = useState('');
  const [loading, setLoading] = useState(true);
  const [confirmCardTouched, setConfirmCardTouched] = useState(false);
  const [confirmCard, setConfirmCard] = useState('');

  const [phrase, setPhrase] = useState('');
  const [errorText, setErrorText] = useState('');
  const [inputsDisabled, setInputsDisabled] = useState(false);
  const [banksOptions, setBanksOptions] = useState([]);
  const token = get(props, 'match.params.token', '');
  const [alreadyUsed, setAlreadyUsed] = useState('');
  const [lastDigits, setLastDigits] = useState('');
  const [bank, setBank] = useState('');
  const [newBank, setNewBank] = useState('');

  const [clientInfo, setClientInfo] = useState(null);
  const [loadingFull, setLoadingFull] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [valeCanjeado, setValeCanjeado] = useState('');

  const [showIdentityValidator, setShowIndentityValidator] = useState(false);
  const [showVideoCapturer, setShowVideoCapturer] = useState(false);

  const [voucherRedeemed, setVaucherRedeemed] = useState(false);

  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [suburb, setSuburb] = useState('');
  const [suburbsOptions, setSuburbsOptions] = useState([]);
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');

  const [newPostalCode, setNewPostalCode] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newState, setNewState] = useState('');
  const [newSuburb, setNewSuburb] = useState('');
  const [newStreet, setNewStreet] = useState('');
  const [newNumber, setNewNumber] = useState('');

  const [startExchange, setStartExchange] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [existAddress, setExistAddress] = useState(false);
  const [existCard, setExistCard] = useState(null);

  const [myBankName, setMyBankName] = useState('');

  const [confirmCancelVaucher, setConfirmCancelVaucher] = useState(false);

  const [parentescoOptions, setParentescoOptions] = useState([]);
  const [beneficiarios, setBeneficiarios] = useState([
    {
      id: null,
      nombre: '',
      fechaNacimiento: new Date(),
      parentescoId: '',
      porcentaje: 100,
    },
  ]);
  const [beneficiariosCopy, setBeneficiariosCopy] = useState([]);
  const [editBeneficiarios, setEditBeneficiarios] = useState(false);
  const [validBeneficiarios, setValidBeneficiarios] = useState(false);
  const signatureRef = useRef(null);
  const [signature, setSignature] = useState(null);
  const [seguroOptions, setSeguroOptions] = useState([]);
  const [seguroSelected, setSeguroSelected] = useState(8);
  const [cambiaFirma, setCambiaFirma] = useState(false);

  useEffect(() => {
    if (newPostalCode.length === 5) {
      fetchDataAddress();
    }
  }, [newPostalCode]);

  useEffect(() => {
    if (activeStep === 1 && !editAddress) {
      setPostalCode(get(clientInfo, 'cliente.domicilio.codigoPostal', ''));
      setCity(get(clientInfo, 'cliente.domicilio.ciudad', ''));
      setState(get(clientInfo, 'cliente.domicilio.estado', ''));
      setSuburb(get(clientInfo, 'cliente.domicilio.colonia', ''));
      setStreet(get(clientInfo, 'cliente.domicilio.calle', ''));
      setNumber(get(clientInfo, 'cliente.domicilio.numeroExterior', ''));
    }
  }, [
    setPostalCode,
    setCity,
    setState,
    setSuburb,
    setStreet,
    setNumber,
    editAddress,
    activeStep,
  ]);

  const searchFilterFunction = () => {
    const newData = filter(banksOptions, item =>
      includes(toLower(item.id), toLower(bank)),
    );
    const data = get(newData[0], 'nombre');
    setMyBankName(data);
  };

  useEffect(() => {
    searchFilterFunction();
  }, [searchFilterFunction]);

  useEffect(() => {
    if (fileUploaded) {
      handleValidateSelfie();
    }
  }, [fileUploaded]);

  const handleOpenCancelVaocuher = () => {
    setCancelVaucher(true);
  };

  const handleCloseCancelVaucher = () => {
    setCancelVaucher(false);
  };

  const handleCancelVaucher = async () => {
    try {
      setLoading(true);
      const body = {
        token,
      };
      await Axios.post(`${config.api.url}/vales/cancelar-vale`, body);

      setCancelVaucher(false);
      setConfirmCancelVaucher(true);
    } finally {
      setLoading(false);
    }
  };

  const start = () => {
    setStartExchange(true);
  };

  const fetchDataAddress = async () => {
    try {
      setLoading(true);
      const response = await Axios.get(`${config.api.url}/colonias`, {
        params: {
          filter: {
            where: {
              codigoPostal: newPostalCode,
            },
          },
        },
      });
      const items = response?.data || [];
      const [firstItem] = items;
      if (!firstItem) return;
      const { nomMunicipio, nomEstado, nomColonia } = firstItem;
      setNewCity(nomMunicipio);
      setNewState(nomEstado);
      setNewSuburb(nomColonia);
      setSuburbsOptions(items);
    } finally {
      setLoading(false);
    }
  };

  const handleCanjeVale = async () => {
    try {
      setLoadingFull(true);

      setButtonDisabled(true);

      const formData = new FormData();

      if (showIdentityValidator)
        formData.set('id', await urltoFile(fileUploaded));
      formData.set('token', token);
      formData.set('type', 'SPEI');

      if (editCard) {
        formData.set('tarjeta', card.replaceAll(' ', ''));
        formData.set('bancoId', newBank);
      }

      if (!editCard) {
        formData.set('tarjeta', lastDigits.replaceAll(' ', ''));
        formData.set('bancoId', bank);
      }

      if (editAddress) {
        formData.set('calle', newStreet);
        formData.set('ciudad', newCity);
        formData.set('estado', newState);
        formData.set('colonia', newSuburb);
        formData.set('codigoPostal', newPostalCode);
        formData.set('numeroExterior', newNumber);
      }

      if (!editAddress) {
        formData.set('calle', street);
        formData.set('ciudad', city);
        formData.set('estado', state);
        formData.set('colonia', suburb);
        formData.set('codigoPostal', postalCode);
        formData.set('numeroExterior', number);
      }

      if (videoIdentity) {
        const videoFile = new File([videoIdentity], 'validacion-video.mp4', {
          type: 'video/mp4',
        });
        formData.set('video', videoFile);
      }

      if (beneficiarios && validBeneficiarios) {
        formData.set('beneficiarios', JSON.stringify(beneficiarios));
      }

      if (seguroSelected) {
        formData.set('seguroId', seguroSelected);
      }

      const response = await Axios.patch(
        `${config.api.url}/vales/canjearVale`,
        formData,
        {
          'Content-Type': 'multipart/form-data',
        },
      );

      const isSuccess =
        get(response, 'data.success', false) || get(response, 'data', false);

      if (!isSuccess) {
        setErrorText('Error canjeando vale');
        setButtonDisabled(false);
        return;
      }

      if (videoIdentity) {
        setSuccessText('Vale en proceso de autorización');
        setValeCanjeado(
          'Vale en proceso de autorización. Revisaremos tu video para canjear tu vale',
        );
      } else {
        setSuccessText('Vale canjeado con éxito');
        setValeCanjeado('Vale canjeado. ¡Gracias por tu preferencia!');
      }
    } catch (error) {
      setErrorText('Error canjeando vale');
      setButtonDisabled(false);
    } finally {
      setLoadingFull(false);
      setVaucherRedeemed(true);
    }
  };

  const fetchFirma = async firmaDigital => {
    const base64 = await fetch(firmaDigital)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(res => {
          reader.onloadend = () => {
            res(reader.result);
          };
        });
      });
    const signatureEl = signatureRef.current;
    signatureEl.fromDataURL(base64);
    setSignature(firmaDigital);
    setCambiaFirma(false);
  };
  const filesRef = useRef(null);

  useEffect(() => {
    fetchData();
    fetchConfig();
  }, []);

  useEffect(() => {
    if (activeStep === 4) {
      if (signature) fetchFirma(signature);
    }
  }, [activeStep]);

  const handleNext = async () => {
    if (activeStep === 4 && cambiaFirma) {
      await guardarFirma();
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    if (activeStep === 5) {
      setTermsAgreed(false);
    }
    if (activeStep === 3) {
      if (beneficiariosCopy.length !== 0) {
        setBeneficiarios(beneficiariosCopy);
      }
      setEditBeneficiarios(false);
    }
  };

  const handleOpenCamera = () => {
    setCameraOpen(true);
    handleCloseMenu();
  };

  const handleTermsAgreedOpen = () => {
    setTermsAgreedOpen(true);
  };

  const handleTermsAgreedClose = () => {
    setTermsAgreedOpen(false);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseCamera = () => {
    setCameraOpen(false);
    setCameraStarted(false);
  };

  const handlePhotoTook = dataURI => {
    setFileUploaded(dataURI);
    setCameraOpen(false);
  };

  const handleOpenVideo = () => {
    setVideoOpen(true);
    setVideoURL(null);
    setVideo(null);
  };

  const handleCloseVideo = () => {
    setVideoOpen(false);
    setCameraStarted(false);
  };

  const handleOpenFileInput = () => {
    filesRef.current.click();
    handleCloseMenu();
  };

  const handleStopRecording = videoBlob => {
    setVideoOpen(false);
    setVideo(videoBlob);
    setVideoURL(URL.createObjectURL(videoBlob));
  };

  const errorConfirmCard = confirmCardTouched && confirmCard !== card;

  const handleValidateSelfie = async () => {
    try {
      setLoadingFull(true);

      const formData = new FormData();

      const valeId = get(clientInfo, 'id', '');

      formData.set('valeId', valeId);
      formData.set('selfie', await urltoFile(fileUploaded));

      const response = await Axios.patch(
        `${config.api.url}/vales/validarCliente`,
        formData,
        {
          'Content-Type': 'multipart/form-data',
        },
      );

      const isSuccess = get(response, 'data.success', false);

      if (!isSuccess) {
        setErrorText('Error validando la identidad');
        setButtonDisabled(false);
        setErrorSelfie('Error validando la identidad');
      }
    } catch (error) {
      setErrorText('Error validando identidad');
      setErrorSelfie('Error validando la identidad');
      setValidationAttempt(validationAttempt + 1);
    } finally {
      setLoadingFull(false);
    }
  };

  const handleChangeFiles = async () => {
    setErrorSelfie('');
    const files = Array.from(filesRef.current.files);
    if (files.some(f => f.size > 50 * 1000000)) {
      setErrorText('Foto demasiado pesada');
      return;
    }
    if (!files.length) return;
    const file = files[0];
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function() {
        setFileUploaded(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const editCardStatus = e => {
    setEditCard(e);
    if (editCard) {
      setCardTouched(false);
      setInputsDisabled(false);
      setShowIndentityValidator(true);
      fetchData();
    }
  };

  const editAddressStatus = e => {
    setEditAddress(e);
    if (editAddress) {
      fetchData();
    }
  };

  const fetchConfig = async () => {
    try {
      const response = await Axios.get(`${config.api.url}/configuraciones`);
      setTermsAndConditions(get(response, 'data[0].terminosCondiciones', ''));
      setIdentityValidationAttempts(
        get(response, 'data[0].intentosValidacionIdentidad', ''),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      let response = await Axios.get(`${config.api.url}/bancos`);
      const banks = get(response, 'data', []) || [];
      setBanksOptions(banks);

      response = await Axios.get(`${config.api.url}/parentescos`, {
        params: {
          filter: {
            where: {
              estatus: 'activo',
            },
          },
        },
      });
      const parentescos = get(response, 'data', []) || [];
      setParentescoOptions(parentescos);

      response = await Axios.get(`${config.api.url}/seguros`, {
        params: {
          filter: {
            where: {
              estatus: 'activo',
            },
          },
        },
      });
      const seguro = get(response, 'data', []) || [];
      setSeguroOptions(seguro);

      response = await Axios.get(`${config.api.url}/vales`, {
        params: {
          filter: {
            where: {
              token,
            },
            include: [
              'cliente',
              'validacionesVideoFrases',
              'validacionesVideos',
            ],
          },
        },
      });

      if (!get(response, 'data[0]')) {
        setNotFound(true);
      }

      const voucherStatus = get(response, 'data[0].estatus', '');

      const firmaDigital = get(response, 'data[0].cliente.firmaDigital', '');

      if (!isEmpty(firmaDigital)) {
        setSignature(firmaDigital);
      }
      if (voucherStatus !== 'porcobrar') {
        if (voucherStatus === 'cancelado') {
          setAlreadyUsed(
            'Este vale ha sido cancelado, por favor contacta a tu mayorista para más información.',
          );
        } else {
          setAlreadyUsed(
            'Este vale ya fue canjeado. ¡Gracias por tu preferencia!',
          );
        }
        return;
      }

      const videoStatus = get(
        response,
        'data[0].validacionesVideos.estatus',
        '',
      );

      if (videoStatus === 'porvalidar') {
        setAlreadyUsed('Este vale está en proceso de validación por video.');
        return;
      }

      const idType = !!get(
        response,
        'data[0].cliente.tipoIdentificacion',
        false,
      );

      if (!idType) setShowVideoCapturer(true);

      const estatus = get(response, 'data[0].cliente.estatus', '');

      if (estatus === 'preregistro') {
        setShowIndentityValidator(true);
      }

      const digits = get(response, 'data[0].cliente.tarjeta', '');
      if (digits) {
        setLastDigits(digits);
        setCardTouched(true);
      } else {
        setInputsDisabled(false);
      }

      const bankId = get(response, 'data[0].cliente.bancoId', '');
      if (bankId) {
        setBank(bankId);
        setNewBank(bankId);
      }

      const responsePhrase = get(
        response,
        'data[0].validacionesVideoFrases.texto',
        '',
      );
      if (responsePhrase) {
        setPhrase(responsePhrase);
      }
      setClientInfo(get(response, 'data[0]', {}));
      setValidationAttempt(get(response, 'data[0].intentoValidacionIdentidad'));
      const savedAddress = get(response, 'data[0].cliente.domicilio', {});
      if (!savedAddress) {
        setEditAddress(true);
      }
      setExistAddress(savedAddress);
      setExistCard(get(response, 'data[0].cliente.tarjeta', {}) || null);
      if (isEmpty(get(response, 'data[0].cliente.tarjeta', {}))) {
        editCardStatus(true);
      }
      const clienteId = get(response, 'data[0].clienteId', '');
      response = await Axios.get(`${config.api.url}/beneficiarios`, {
        params: {
          filter: {
            where: {
              clienteId,
              estatus: 'activo',
            },
          },
        },
      });
      const beneficiaries = get(response, 'data', []) || [];
      if (beneficiaries.length !== 0) {
        setValidBeneficiarios(true);
        setBeneficiarios(beneficiaries);
        setBeneficiariosCopy(beneficiaries);
      } else {
        setEditBeneficiarios(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoadingFull(false);
    }
  };

  const handleValidateBeneficiarios = isValid => {
    setValidBeneficiarios(isValid);
  };

  const onSignatureEnd = () => {
    const signatureEl = signatureRef.current;
    if (!signatureEl.isEmpty()) {
      setCambiaFirma(true);
      setSignature(signatureEl.toDataURL());
    }
  };

  const clearSignature = () => {
    const signatureEl = signatureRef.current;
    signatureEl.clear();
    setSignature(null);
  };

  const guardarFirma = async () => {
    try {
      setLoadingFull(true);
      const formData = new FormData();
      const base64Response = await fetch(signature);
      const blob = await base64Response.blob();
      const firmaFile = new File([blob], 'firma.png', { type: 'image/png' });
      const clienteId = get(clientInfo, 'clienteId', '');
      formData.append('firma', firmaFile);
      await Axios.patch(
        `${config.api.url}/clientes/subirFirma?clienteId=${clienteId}`,
        formData,
      );
    } catch (e) {
      setErrorText('Error al subir imagen de la firma del cliente');
    } finally {
      setLoadingFull(false);
      setSuccessText('Firma enviada con éxito');
    }
  };

  const handleCancelEditBeneficiarios = () => {
    setEditBeneficiarios(false);
    setBeneficiarios(beneficiariosCopy);
  };

  const disabled =
    buttonDisabled ||
    !termsAgreed ||
    (showIdentityValidator && !fileUploaded && !videoIdentity) ||
    (!inputsDisabled && card !== confirmCard) ||
    (errorSelfie && !showVideoCapturer);

  const errorCard = cardTouched && !isCreditCard(card);

  return (
    <ContainerGeneral>
      <NavBarContainer>
        <Link to="/">
          <img
            className="logo"
            src="/images/logo-new.png"
            alt="Logo PrestaVale blanco"
          />
        </Link>
      </NavBarContainer>
      {startExchange === false && (
        <>
          {loading && <Loader />}
          {!notFound && Boolean(alreadyUsed) && (
            <>
              <Content>
                <h1>Canje digital por transferencia SPEI</h1>
                <div>{alreadyUsed}</div>
              </Content>
            </>
          )}
          {notFound && (
            <Content>
              <div>
                Este vale no existe o el link es incorrecto. Contacte a su
                distribuidor.
              </div>
            </Content>
          )}
          {!loading && !alreadyUsed && !notFound && (
            <>
              <Content>
                <h1>Canje digital por transferencia SPEI</h1>
                {confirmCancelVaucher === false && (
                  <>
                    <div className="firstLabel">{`¡Hola, ${get(
                      clientInfo,
                      'cliente.nombreCompleto',
                      '',
                    )}!`}</div>
                    <ContainerData>
                      <h3>Iniciaras tu canje de vale por</h3>
                      <div className="number">
                        {Numeral(clientInfo?.monto || 0).format('$ 0,0.00')}
                      </div>
                    </ContainerData>
                    <ContainerData>
                      <h3>Plazo: </h3>
                      <div className="numbertwo">{`${clientInfo?.cantidadPagos ||
                        ''} quincenas`}</div>
                    </ContainerData>
                    <ContainerData>
                      <h3>Pago quincenal: </h3>
                      <div className="numbertwo">
                        {Numeral(clientInfo?.pagoQuincenal || 0).format(
                          '$ 0,0.00',
                        )}
                      </div>
                    </ContainerData>

                    <ContentButonGroup>
                      <ContentButon>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={start}
                        >
                          Comenzar canje
                        </Button>
                      </ContentButon>
                      <ContentButon>
                        <Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={handleOpenCancelVaocuher}
                        >
                          No quiero el vale
                        </Button>
                      </ContentButon>
                    </ContentButonGroup>
                  </>
                )}

                {confirmCancelVaucher === true && (
                  <>
                    <div className="greeting">Vale cancelado</div>
                    <h2>{`El vale con el folio: ${get(
                      clientInfo,
                      'folio',
                      '',
                    )} ha sido cancelado exitosamente.`}</h2>
                  </>
                )}

                <Dialog
                  open={cancelVaucher}
                  onClose={handleCloseCancelVaucher}
                  maxWidth="sm"
                >
                  <IconButton
                    style={{
                      position: 'absolute',
                      top: 5,
                      right: 5,
                      zIndex: 10,
                    }}
                    onClick={handleCloseCancelVaucher}
                  >
                    <CloseIcon />
                  </IconButton>
                  <ContentDialog>
                    <h1>¿Estás seguro de que no quieres el vale?</h1>
                    <h2>Al rechazarlo se cancelará y no podrás canjearlo</h2>
                    <ButtonGrupDialog>
                      <Button
                        color="primary"
                        onClick={handleCloseCancelVaucher}
                        style={{ textTransform: 'none', marginRight: 15 }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCancelVaucher}
                        style={{ textTransform: 'none' }}
                      >
                        Aceptar
                      </Button>
                    </ButtonGrupDialog>
                  </ContentDialog>
                </Dialog>
              </Content>
            </>
          )}
        </>
      )}

      {startExchange && (
        <>
          {activeStep === 0 && editCard === false && existCard !== null ? (
            <>
              <Content>
                <h1>Canje digital por transferencia SPEI</h1>

                {loading && <div>Cargando...</div>}
                {!notFound && Boolean(alreadyUsed) && (
                  <>
                    <div>{alreadyUsed}</div>
                  </>
                )}
                {notFound && (
                  <div>
                    Este vale no existe o el link es incorrecto. Contacte a su
                    distribuidor
                  </div>
                )}

                {Boolean(valeCanjeado) && (
                  <div className="greeting">{valeCanjeado}</div>
                )}
                <div className="greeting">Datos bancarios</div>
                <ContainerCard>
                  <h2>Número de tarjeta</h2>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => editCardStatus(true)}
                  >
                    Editar tarjeta
                  </Button>
                </ContainerCard>
                <h2>{`**** **** **** ${lastDigits.substring(12)}`}</h2>
                <h2>Banco</h2>
                <h2>{myBankName}</h2>
              </Content>
            </>
          ) : null}
        </>
      )}

      {(startExchange && activeStep === 0 && editCard) ||
      (startExchange && activeStep === 0 && existCard === null) ? (
        <Content>
          <h1>Canje digital por transferencia SPEI</h1>
          <div className="greeting">Datos bancarios</div>
          <ContainerCard>
            <h2>Número de tarjeta</h2>
            {editCard && (
              <Button
                variant="text"
                color="primary"
                onClick={() => editCardStatus(false)}
              >
                Cancelar
              </Button>
            )}
          </ContainerCard>
          <TextField
            id="card"
            className="input"
            error={errorCard}
            helperText={errorCard && 'Ingrese una tarjeta válida'}
            value={card}
            placeholder="Número de tarjeta"
            variant="outlined"
            onChange={e => setCard(e.target.value)}
            onBlur={() => {
              setCardTouched(true);
              setShowIndentityValidator(true);
            }}
            InputProps={{
              inputComponent: TextMaskCustom,
              inputProps: {
                placeholder: 'Ingresa los 16 dígitos de tu tarjeta',
              },
            }}
            autoFocus
            disabled={inputsDisabled}
          />
          <TextField
            id="card"
            className="input"
            error={errorConfirmCard}
            helperText={errorConfirmCard && 'Las tarjetas no coinciden'}
            value={confirmCard}
            placeholder="Confirme número de tarjeta"
            variant="outlined"
            onChange={e => setConfirmCard(e.target.value)}
            onBlur={() => setConfirmCardTouched(true)}
            InputProps={{
              inputComponent: TextMaskCustom,
              inputProps: {
                placeholder: 'Confirme número de tarjeta',
              },
            }}
            disabled={inputsDisabled}
          />
          <h2>Banco</h2>
          <FormControl variant="outlined" className="input">
            <Select
              labelId="bank-label"
              id="bank"
              value={newBank}
              onChange={event => setNewBank(event.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Banco' }}
              style={{ color: bank ? '#212121' : '#a2a2a2' }}
              disabled={inputsDisabled}
            >
              <MenuItem value="" disabled>
                Banco
              </MenuItem>
              {banksOptions.map(c => (
                <MenuItem value={c.id} key={c.id}>
                  {c.nombre}
                </MenuItem>
              ))}
              <FormHelperText>Banco</FormHelperText>
            </Select>
          </FormControl>
        </Content>
      ) : null}
      {activeStep === 1 && (
        <Content>
          <h1>Canje digital por transferencia SPEI</h1>
          {!editAddress ? (
            <div>
              <ContainerCard>
                <div className="greeting">Dirección</div>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => editAddressStatus(true)}
                >
                  Editar dirección
                </Button>
              </ContainerCard>
              <div>
                {`${get(clientInfo, 'cliente.domicilio.calle', '')} `}
                {`#${get(clientInfo, 'cliente.domicilio.numeroExterior', '')} `}
                {`C.P. ${get(
                  clientInfo,
                  'cliente.domicilio.codigoPostal',
                  '',
                )} `}
                {`Col. ${get(clientInfo, 'cliente.domicilio.colonia', '')}. `}
                {`${get(clientInfo, 'cliente.domicilio.ciudad', '')}, `}
                {`${get(clientInfo, 'cliente.domicilio.estado', '')}.`}
              </div>
            </div>
          ) : (
            <div>
              <ContainerCard>
                <div className="greeting">Dirección</div>
                {existAddress && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => editAddressStatus(false)}
                  >
                    Cancelar
                  </Button>
                )}
              </ContainerCard>
              <h2>Codigo postal</h2>
              <TextField
                id="postal-code"
                value={newPostalCode}
                variant="outlined"
                onChange={e => {
                  const { value } = e.target;
                  if (!value || numberRegex.test(value)) {
                    setNewPostalCode(value);
                  }
                }}
                fullWidth
                inputProps={{ maxLength: 5 }}
              />

              <h2>Ciudad</h2>
              <TextField
                id="city"
                value={newCity}
                onChange={e => setNewCity(e.target.value)}
                variant="outlined"
                fullWidth
              />
              <h2>Estado</h2>
              <TextField
                id="state"
                value={newState}
                onChange={e => setNewState(e.target.value)}
                variant="outlined"
                fullWidth
              />

              <h2>Colonia</h2>
              <FormControl variant="outlined" fullWidth>
                <Select
                  labelId="suburb-label"
                  id="suburb"
                  value={newSuburb}
                  onChange={event => setNewSuburb(event.target.value)}
                >
                  {suburbsOptions.map(c => (
                    <MenuItem value={c.nomColonia} key={c.id}>
                      {c.nomColonia}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <h2>Calle</h2>
              <TextField
                id="street"
                value={newStreet}
                variant="outlined"
                onChange={e => setNewStreet(e.target.value)}
                fullWidth
              />

              <h2>Número exterior</h2>
              <TextField
                id="number"
                value={newNumber}
                variant="outlined"
                onChange={e => {
                  const { value } = e.target;
                  if (!value || numberRegex.test(value)) {
                    setNewNumber(value);
                  }
                }}
                fullWidth
                inputProps={{ maxLength: 5 }}
              />
            </div>
          )}
        </Content>
      )}
      {activeStep === 2 && (
        <Content>
          <h1>Seguro de Vida</h1>
          <ContainerCard>
            <div className="greeting">
              Selecciona un paquete de seguro de vida. La cuota del seguro se
              sumará a tu pago quincenal.
            </div>
          </ContainerCard>
          <RadioGroup
            aria-label="options"
            name="options"
            value={seguroSelected}
          >
            {seguroOptions.map(option => (
              <Card
                key={option.id}
                variant="outlined"
                style={{
                  marginBottom: '8px',
                  border: `3px solid ${
                    seguroSelected === option.id ? '#00843d' : '#00000042'
                  }`,
                  cursor: 'pointer',
                }}
                onClick={() => setSeguroSelected(option.id)}
              >
                <CardContent>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ fontWeight: 'bold' }}>{option.nombre}</div>
                    <div style={{ color: '#00843d' }}>
                      $ {option.montoAPagar.toFixed(2)} quincenal
                    </div>
                  </div>
                  <div style={{ color: '#808080' }}>
                    ${Numeral(option.importeAsegurado || 0).format('0,0')}
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      {option.beneficios.map(beneficio => (
                        <div>{beneficio}</div>
                      ))}
                    </div>
                    {option.default && (
                      <div
                        style={{
                          marginLeft: 'auto',
                          backgroundColor: '#00843d',
                          color: 'white',
                          fontWeight: 'bold',
                          borderRadius: '4px',
                          padding: '4px',
                          marginBottom: 'auto',
                        }}
                      >
                        ¡Recomendado!
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>
            ))}
          </RadioGroup>
        </Content>
      )}
      {activeStep === 3 && (
        <Content>
          <h1>Beneficiario(s) de Seguro de Vida</h1>
          <ContainerCard>
            <div className="greeting">
              Estoy de acuerdo que el uso de medios electrónicos será utilizado
              para otorgar el consentimiento y la designación de beneficiarios
            </div>
          </ContainerCard>
          {!editBeneficiarios && beneficiariosCopy.length !== 0 ? (
            <div>
              <ContainerCard style={{ marginTop: '20px' }}>
                <div className="greeting">Beneficiarios</div>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setEditBeneficiarios(!editBeneficiarios)}
                >
                  Editar beneficiarios
                </Button>
              </ContainerCard>
              <div>
                {beneficiarios.map(beneficiario => (
                  <div key={beneficiario.id} style={{ marginBottom: '20px' }}>
                    <div>
                      <strong>Nombre:</strong> {beneficiario.nombre}
                    </div>
                    <div>
                      <strong>Parentesco:</strong>{' '}
                      {parentescoOptions
                        .find(
                          parentesco =>
                            parentesco.id === beneficiario.parentescoId,
                        )
                        .nombre.toLowerCase()}
                    </div>
                    <div>
                      <strong>Fecha de nacimiento:</strong>{' '}
                      {moment(beneficiario.fechaNacimiento)
                        .locale('es')
                        .format('DD MMMM YYYY')}
                    </div>
                    <div>
                      <strong>Porcentaje asignado:</strong>{' '}
                      {beneficiario.porcentaje}%
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <ContainerCard style={{ marginTop: '20px' }}>
                <div className="greeting">Beneficiarios</div>
                {beneficiariosCopy.length !== 0 && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => handleCancelEditBeneficiarios()}
                  >
                    Cancelar
                  </Button>
                )}
              </ContainerCard>
              <BeneficiariosForm
                parentescos={parentescoOptions}
                beneficiarios={beneficiarios}
                setBeneficiariosArray={setBeneficiarios}
                onValidation={handleValidateBeneficiarios}
              />
            </div>
          )}
        </Content>
      )}

      {activeStep === 4 && (
        <Content>
          <h1>Firma del cliente</h1>
          <ContainerCard>
            <div className="greeting">Firma</div>
          </ContainerCard>
          <ContainerCanvas>
            <SignatureCanvas
              penColor="black"
              ref={signatureRef}
              onEnd={() => onSignatureEnd()}
              canvasProps={{
                width: 400,
                height: 200,
                className: 'sigCanvas',
              }}
            />
          </ContainerCanvas>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button color="primary" onClick={clearSignature}>
              Reiniciar
            </Button>
          </div>
        </Content>
      )}

      {activeStep === 5 && voucherRedeemed === false ? (
        <Content>
          <h1>Canje digital por transferencia SPEI</h1>
          <div className="greeting">Validación de identidad</div>

          {/* eslint-disable */}
          {Boolean(videoIdentity) && (
            <>
              <video
                src={videoURL}
                style={{
                  display: `${videoIdentity ? 'block' : 'none'}`,
                }}
                controls
                playsInline
              />
            </>
          )}
          {/* eslint-enable */}
          {Boolean(videoIdentity) && (
            <Button
              variant="text"
              color="primary"
              onClick={handleOpenVideo}
              style={{ marginTop: 12 }}
            >
              Volver a grabar
            </Button>
          )}
          {showIdentityValidator &&
            (showVideoCapturer ? (
              <>
                {!videoIdentity && (
                  <VideoSection onClick={handleOpenVideo}>
                    <div className="title text">
                      Video de validación de identidad
                    </div>
                    <div className="text">
                      Para validar tu identidad es necesario tomar un video con
                      las siguientes características
                    </div>
                    <div className="text">Modo selfie</div>
                    <div className="text">
                      Tener tu INE en la mano y mostrarla a la cámara
                    </div>
                    <div className="text" style={{ marginBottom: 24 }}>
                      Leer el siguiente texto: <b>{phrase}</b>
                    </div>
                    <InputFileContainer>
                      <CameraIcon />
                      <span>Grabar video</span>
                    </InputFileContainer>
                  </VideoSection>
                )}
              </>
            ) : (
              <>
                {validationAttempt <= identityValidationAttempts && (
                  <>
                    <div className="label-description">
                      Toma una selfie para confirmar tu identidad
                    </div>
                    <InputFileContainer
                      error={errorSelfie}
                      onClick={handleOpenCamera}
                    >
                      {fileUploaded ? (
                        <img src={fileUploaded} alt="Img" />
                      ) : (
                        <>
                          <CameraIcon />
                          <span>Tomar una selfie</span>
                        </>
                      )}
                    </InputFileContainer>
                  </>
                )}
              </>
            ))}

          {validationAttempt <= identityValidationAttempts && (
            <>
              {errorSelfie && !showVideoCapturer && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleOpenCamera}
                  style={{ marginTop: 12 }}
                >
                  Reintentar selfie
                </Button>
              )}
            </>
          )}

          {errorSelfie && !showVideoCapturer && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowVideoCapturer(true)}
              style={{ marginTop: 12 }}
            >
              Intentar con video
            </Button>
          )}
          <ContentTerms>
            <FormControlLabel
              control={<Checkbox name="accept" color="primary" />}
              // label="Aceptas términos y condiciones."
              className="accept-check"
              onChange={event => setTermsAgreed(event.target.checked)}
            />
            <Button onClick={handleTermsAgreedOpen}>
              Aceptas términos y condiciones.
            </Button>
          </ContentTerms>

          <Menu
            id="picture-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleOpenFileInput}>Galería</MenuItem>
            <MenuItem onClick={handleOpenCamera}>Cámara</MenuItem>
          </Menu>
          <Input
            ref={filesRef}
            type="file"
            value=""
            onChange={handleChangeFiles}
            accept=".png, .jpg, .jpeg"
          />
          <Dialog open={cameraOpen} onClose={handleCloseCamera} maxWidth="sm">
            <IconButton
              style={{ position: 'absolute', top: 16, right: 16, zIndex: 1 }}
              onClick={handleCloseCamera}
            >
              <CloseIcon />
            </IconButton>
            {!cameraStarted && <LoadingLabel>Abriendo cámara...</LoadingLabel>}
            <Camera
              onTakePhoto={handlePhotoTook}
              onCameraStart={() => {
                setCameraStarted(true);
              }}
            />
          </Dialog>

          <Snackbar
            open={Boolean(successText)}
            autoHideDuration={6000}
            onClose={() => setSuccessText('')}
          >
            <Alert onClose={() => setSuccessText('')} severity="success">
              {successText}
            </Alert>
          </Snackbar>
          <Snackbar
            open={Boolean(errorText)}
            autoHideDuration={6000}
            onClose={() => setErrorText('')}
          >
            <Alert onClose={() => setErrorText('')} severity="error">
              {errorText}
            </Alert>
          </Snackbar>

          <Dialog open={videoOpen} onClose={handleCloseVideo} maxWidth="sm">
            <IconButton
              style={{ position: 'absolute', top: 16, right: 16, zIndex: 10 }}
              onClick={handleCloseVideo}
            >
              <CloseIcon />
            </IconButton>
            <div
              style={{
                width: 600,
                height: 400,
                maxHeight: '100%',
                maxWidth: '100%',
              }}
            >
              <VideoRecorder
                constraints={{
                  audio: true,
                  video: {
                    facingMode: 'user',
                  },
                }}
                onRecordingComplete={handleStopRecording}
                isOnInitially
                renderActions={videoProps => (
                  <VideoActions {...videoProps} phrase={phrase} />
                )}
                timeLimit={30 * 1000}
              />
            </div>
          </Dialog>
        </Content>
      ) : null}

      {voucherRedeemed === true && (
        <Content>
          <h1>Canje digital por transferencia SPEI</h1>
          <div className="greeting">{`Listo, ${get(
            clientInfo,
            'cliente.nombreCompleto',
            '',
          )}!`}</div>
          <div className="instructions">{valeCanjeado}</div>
        </Content>
      )}

      {startExchange && (
        <>
          {voucherRedeemed === false && (
            <MobileStepper
              variant="dots"
              steps={6}
              activeStep={activeStep}
              nextButton={
                <>
                  {activeStep === 5 ? (
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      onClick={handleCanjeVale}
                      disabled={disabled}
                      style={{ textTransform: 'none' }}
                    >
                      Canjear
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      onClick={handleNext}
                      color="primary"
                      disabled={
                        activeStep === 5 ||
                        (editAddress &&
                          activeStep === 1 &&
                          (isEmpty(newPostalCode) ||
                            isEmpty(newCity) ||
                            isEmpty(newState) ||
                            isEmpty(newSuburb) ||
                            isEmpty(newStreet) ||
                            isEmpty(newNumber))) ||
                        (editCard && isEmpty(card)) ||
                        (editCard && isEmpty(confirmCard)) ||
                        (editCard &&
                          activeStep === 0 &&
                          confirmCard !== card &&
                          errorCard) ||
                        (editCard && !newBank) ||
                        (editCard &&
                          activeStep === 0 &&
                          cardTouched &&
                          !isCreditCard(card)) ||
                        (editCard &&
                          activeStep === 0 &&
                          confirmCardTouched &&
                          confirmCard !== card) ||
                        (activeStep === 2 && !seguroSelected) ||
                        (editBeneficiarios &&
                          activeStep === 3 &&
                          !validBeneficiarios) ||
                        (activeStep === 4 && !signature)
                      }
                      style={{ textTransform: 'none' }}
                    >
                      Siguiente
                    </Button>
                  )}
                </>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  color="primary"
                  disabled={activeStep === 0}
                  style={{ textTransform: 'none' }}
                >
                  Atrás
                </Button>
              }
            />
          )}
        </>
      )}

      {loadingFull && <Loader />}
      <Dialog
        open={termsAgreedOpen}
        onClose={handleTermsAgreedClose}
        maxWidth="sm"
      >
        <IconButton
          style={{ position: 'absolute', top: 16, right: 16, zIndex: 1 }}
          onClick={handleTermsAgreedClose}
        >
          <CloseIcon />
        </IconButton>
        <ContainerDialogTerms>
          <div>{parse(termsAndConditions, options)}</div>
        </ContainerDialogTerms>
      </Dialog>
    </ContainerGeneral>
  );
};

export default CanjeValeDigital;
